import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        laave2: file(
          sourceInstanceName: { eq: "lager" }
          name: { eq: "laavelager3" }
        ) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        minilager5: file(
          sourceInstanceName: { eq: "minilager" }
          name: { eq: "minilager5" }
        ) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="lager">
        <Container>
          <h2>Ulike lagerløsninger</h2>
          <Grid>
            <div>
              <Art>
                <Img fluid={data.laave2.childImageSharp.fluid} />
              </Art>
              <div>
                <h3>
                  Campingvognen din står trygt og tørt i vårt uisolerte
                  låvelager
                </h3>
                <p>
                  Les mer om{' '}
                  <Link to="/campingvogn">lagring av campingvogn</Link>
                </p>
              </div>
            </div>

            <div>
              <Art>
                <Img fluid={data.minilager5.childImageSharp.fluid} />
              </Art>
              <div>
                <h3>
                  Minilager - lagerplass til utstyr det er vanskelig å finne
                  plass til hjemme
                </h3>
                <p>
                  <p>
                    Les mer om <Link to="/minilager">minilager</Link>
                  </p>
                </p>
              </div>
            </div>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 40px;
  align-items: top;
  justify-items: top;
  margin: 24px 0;
  height: 100%;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h3 {
    margin-bottom: 16px;
    font-size: 1.4rem;
    line-height: 1.3;
    font-weight: 700;
    margin-top: 1rem;
  }

  p, a {
    line-height: 1.3;
    font-size: 1.2rem;

  }

  a {
    color: #000;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  width: 100%;
  max-height: 260px;
  object-fit: contain;
  overflow: hidden;
  object-position: center center;
  img {
    max-height: 260px;
  }
`;

export default About;
