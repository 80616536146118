import React from 'react';
import styled from 'styled-components';

import { Section, Container } from '@components/global';


const Map = () => (
      <Section id="kontakt" accent="secondary">
        <Container style={{ position: 'relative' }}>
          <StyledDiv>
            <h1>Kontakt oss</h1>
            <p>Besøksadresse: <a href="https://www.google.com/maps?ll=59.501367,11.367623&z=17&t=m&hl=no&gl=NO&mapclient=embed&q=Skollerudveien+12+1880+Eidsberg">Skollerudveien 12, 1880 Eidsberg</a> </p>
            <p>
                Telefon: <a href="tel:90743548">907 43 548</a> / <a href="tel:93410594">934 10 594</a>
              </p>
              <p>Epost: <a href="mailto:post@laavelager.no">post@laavelager.no</a></p>
          </StyledDiv>
            <ResponsiveMap>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2024.9038154138125!2d11.365428451796525!3d59.50136738162972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4643f1b33ef0265f%3A0xb9149e58adda5269!2sSkollerudveien%2012%2C%201880%20Eidsberg!5e0!3m2!1sno!2sno!4v1599683807775!5m2!1sno!2sno" width="600" height="300" frameborder="0" style={{border:'0'}} allowfullscreen></iframe>
            </ResponsiveMap>
        </Container>
      </Section>
);

const ResponsiveMap = styled.div`
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
    iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
`

const StyledDiv  = styled.div`
padding-bottom: 2rem;
p  {
  margin-top: 1rem;
}
p, a {
    line-height: 1.3;
    font-size: 1.2rem;

  }

  a {
    color: #000;
  }
`



export default Map;
